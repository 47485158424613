import React from "react";
import propng from "../Images/propng.png";
import buchanIcon from "../Images/buchanIcon.jpg";
import clock from "../Images/clock.png";
import comment_6 from "../Images/comment_6.png";
import speedMeter from "../Images/speedMeter.png";
import markIcon from "../Images/markIcon.jpg";
import alerticon from "../Images/alerticon.png";
import alertRed from "../Images/alertRed.png";
import Header from "../component/DashboardCom/Header";

const ManageAthletes = () => {
  return (
    <>
      <Header />

      <div className="appContainerLibrayAndContentContainer">
        <div className="appContainerMainContentContainer">
          <div className="appContainerMainContentContainerScrollContainer">
            <div className="manageAthletes">
              <div
                className="manageAthletesHeader"
                data_cy="manageAthletesHeader"
              >
                <div className="searchInput undefined">
                  <input type="text" placeholder="Search" defaultValue />
                  <div className="searchInputIcon" />
                </div>
                <div className="manageAthletesHeaderFilters">
                  <div className="manageAthleteFilters">
                    <span className="filterBy">Filter by:</span>
                    <select
                      dir="ltr"
                      className="  manageAthletesFilter accountTypeFilter no-background"
                      data-tooltip
                    >
                      <option value="NONE_SELECTED">All Account Types</option>
                      <option value="Basic">Basic</option>
                      <option value="Premium (Coach Paid)">
                        Premium (Coach Paid)
                      </option>
                      <option value="Premium Trial">Premium Trial</option>
                      <option value="Premium (Self Paid)">
                        Premium (Self Paid)
                      </option>
                      <option value="Shared">Shared</option>
                    </select>
                    <select
                      dir="ltr"
                      className="  manageAthletesFilter groupFilter no-background"
                      data-tooltip
                    >
                      <option value="NONE_SELECTED">All Groups</option>
                      <option value={44746}>My Athletes</option>
                      <option value={145507}>12 wee difference program </option>
                      <option value={61261}>Aaron Buchan's Coached Crew</option>
                      <option value={127935}>Elite riders 2020 MSA</option>
                      <option value={79857}>MOtor cycling AU </option>
                      <option value={61956}>
                        Multisport athletes on plans
                      </option>
                      <option value={104435}>Never started </option>
                      <option value={61262}>Online coaching</option>
                      <option value={66459}>Robs Clients </option>
                      <option value={61266}>Swim coaching members</option>
                      <option value={85241}>
                        multisport athletes off program ATM{" "}
                      </option>
                      <option value={61267}>multisport leads </option>
                      <option value={101225}>previous members </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="manageAthletesCoachesScrollContainer">
                <div className="manageAthletesCoach">
                  <div>
                    <div className="manageAthletesCoachHeader">
                      <div className="manageAthletesCoachHeaderCoachDetails">
                        <div className="manageAthletesCoachExpandIcon isExpanded" />
                        <div
                          className="manageAthletesProfilePhoto"
                          data_cy="manageAthletesProfilePhoto"
                          style={{
                            backgroundImage:
                              'url("https://userdata.trainingpeaks.com/8451271/profile/person-photo-images/636259711110482870-300x300.jpg")',
                          }}
                        />
                        <div className="manageAthletesCoachHeaderNameAndAccount">
                          <span className="manageAthletesCoachName">
                            Aaron Buchan
                          </span>
                          <div className="manageAthletesCoachAccountType">
                            <span>Coach Edition Unlimited</span>
                            <span> - </span>
                            <a
                              className="ineligible"
                              data-tooltip="To change your account type you must first reduce the number of attached basic athletes to less than five."
                            >
                              Change
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="manageAthletesCoachHeaderAddButtons">
                        <div className="manageAthletesAddButton addAthlete">
                          <span className="manageAthletesAddButtonButtonText">
                            Add Athlete
                          </span>
                        </div>
                        <div className="manageAthletesAddButton">
                          <span className="manageAthletesAddButtonButtonText">
                            Add Group
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="manageAthletesBulkActions">
                      <div className="manageAthletesSelectAll">
                        <input name="selectall" type="checkbox" />
                        <span>Select All</span>
                      </div>
                      <div className="manageAthletesBulkMoveToGroup disabled">
                        <div
                          className="manageAthletesBulkActionsDisabled"
                          data-tooltip="Select an athlete or athletes to enable bulk actions"
                        />
                        <select
                          dir="ltr"
                          className="  manageAthletesMoveToGroup no-background"
                          data-tooltip
                        >
                          <option value="NONE_SELECTED">
                            Move selected athletes to group
                          </option>
                          <option value={44746}>My Athletes</option>
                          <option value={166840}>10 kgs in 10 days </option>
                          <option value={145507}>
                            12 wee difference program{" "}
                          </option>
                          <option value={74011}>2 week free trial</option>
                          <option value={75281}>4 week free trial </option>
                          <option value={61261}>
                            Aaron Buchan's Coached Crew
                          </option>
                          <option value={127935}>Elite riders 2020 MSA</option>
                          <option value={163619}>M15 program </option>
                          <option value={79857}>MOtor cycling AU </option>
                          <option value={61956}>
                            Multisport athletes on plans
                          </option>
                          <option value={104435}>Never started </option>
                          <option value={61262}>Online coaching</option>
                          <option value={66459}>Robs Clients </option>
                          <option value={61266}>Swim coaching members</option>
                          <option value={85241}>
                            multisport athletes off program ATM{" "}
                          </option>
                          <option value={61267}>multisport leads </option>
                          <option value={101225}>previous members </option>
                        </select>
                      </div>
                      <div className="manageAthletesBulkActionsButtonContainer disabled">
                        <div
                          className="manageAthletesBulkActionsDisabled actions"
                          data-tooltip="Select an athlete or athletes to enable bulk actions"
                        />
                        <div className="manageAthletesRemoveAthlete">
                          <div
                            className="manageAthletesRemoveAthleteButton"
                            data-tooltip="Remove Athlete"
                          >
                            <div className="manageAthletesRemoveAthleteButtonIcon" />
                          </div>
                        </div>
                        <div className="manageAthletesShareAthlete">
                          <div
                            className="manageAthletesShareAthleteButton"
                            data_cy="share"
                            data-tooltip="Share Athlete"
                          >
                            <div className="manageAthletesShareAthleteButtonIcon" />
                          </div>
                        </div>
                        <div
                          className="manageAthletesDownloadCSV"
                          data-tooltip="Export athlete information to a CSV file"
                        >
                          <div className="manageAthletesDownloadCSVIcon" />
                        </div>
                      </div>
                    </div>
                    <div className="manageAthletesAthleteListHeader">
                      <div className="listHeaderColumn Select">
                        <div className="athleteListHeaderColumnName Select">
                          <span>Select</span>
                        </div>
                      </div>
                      <div className="listHeaderColumn Athlete">
                        <div className="sortIndicator ascending" />
                        <div className="athleteListHeaderColumnName Athlete">
                          <span>Athlete</span>
                        </div>
                      </div>
                      <div className="listHeaderColumn Account Type">
                        <div className="sortIndicator" />
                        <div className="athleteListHeaderColumnName Account Type">
                          <span>Account Type</span>
                        </div>
                      </div>
                      <div className="listHeaderColumn Athlete Group">
                        <div className="sortIndicator" />
                        <div className="athleteListHeaderColumnName Athlete Group">
                          <span>Athlete Group</span>
                        </div>
                      </div>
                      <div className="listHeaderColumn Current Coach">
                        <div className="sortIndicator" />
                        <div className="athleteListHeaderColumnName Current Coach">
                          <span>Current Coach</span>
                        </div>
                      </div>
                      <div className="listHeaderColumn Actions">
                        <div className="athleteListHeaderColumnName Actions">
                          <span>Actions</span>
                        </div>
                      </div>
                      <div className="listHeaderColumn Shared Coaches">
                        <div className="sortIndicator" />
                        <div className="athleteListHeaderColumnName Shared Coaches">
                          <span>Shared Coaches</span>
                        </div>
                      </div>
                    </div>
                    <div className="manageAthletesCoachAthletesContainer">
                      <div className="manageAthletesAthleteListRow">
                        <div className="manageAthletesAthleteListRowLargeScreen">
                          <div className="manageAthletesAthleteListColumn select">
                            <input type="checkbox" />
                          </div>
                          <div className="manageAthletesAthleteListColumn athlete">
                            <div className="manageAthletesAthleteProfile canSelect">
                              <div className="athleteAvatar">
                                <div className="athleteAvatarBasicAthlete">
                                  <div className="nophoto avatar" />
                                </div>
                              </div>
                              <span>ava andrews</span>
                            </div>
                          </div>
                          <div className="manageAthletesAthleteListColumn account-type">
                            <div className="manageAthletesAccountType">
                              <span className="manageAthletesAccountTypeLabel">
                                Basic
                              </span>
                              <div className="manageAthletesAccountTypeButton upgrade">
                                <span>Upgrade</span>
                              </div>
                            </div>
                          </div>
                          <div className="manageAthletesAthleteListColumn athlete-group">
                            <select
                              dir="ltr"
                              className="  manageAthletesSelectBox no-background"
                              data-tooltip=""
                            >
                              <option value="44746">My Athletes</option>
                              <option value="166840">10 kgs in 10 days </option>
                              <option value="145507">
                                12 wee difference program{" "}
                              </option>
                              <option value="74011">2 week free trial</option>
                              <option value="75281">4 week free trial </option>
                              <option value="61261">
                                Aaron Buchan's Coached Crew
                              </option>
                              <option value="127935">
                                Elite riders 2020 MSA
                              </option>
                              <option value="163619">M15 program </option>
                              <option value="79857">MOtor cycling AU </option>
                              <option value="61956">
                                Multisport athletes on plans
                              </option>
                              <option value="104435">Never started </option>
                              <option value="61262">Online coaching</option>
                              <option value="66459">Robs Clients </option>
                              <option value="61266">
                                Swim coaching members
                              </option>
                              <option value="85241">
                                multisport athletes off program ATM{" "}
                              </option>
                              <option value="61267">multisport leads </option>
                              <option value="101225">previous members </option>
                            </select>
                          </div>
                          <div className="manageAthletesAthleteListColumn current-coach">
                            <div>
                              <select
                                dir="ltr"
                                className="  manageAthletesSelectBox no-background"
                                data-tooltip=""
                              >
                                <option value="1721548">Aaron Buchan</option>
                              </select>
                            </div>
                          </div>
                          <div className="manageAthletesAthleteListColumn actions">
                            <div className="manageAthleteAthleteActions">
                              <div
                                className="manageAthletesAthleteSettings"
                                data-tooltip="Open athlete settings"
                              >
                                <div className="manageAthletesAthleteSettingsIcon" />
                              </div>
                              <div className="manageAthletesRemoveAthlete">
                                <div
                                  className="manageAthletesRemoveAthleteButton"
                                  data-tooltip="Remove Athlete"
                                >
                                  <div className="manageAthletesRemoveAthleteButtonIcon" />
                                </div>
                              </div>
                              <div className="manageAthletesShareAthlete">
                                <div
                                  className="manageAthletesShareAthleteButton"
                                  data_cy="share"
                                  data-tooltip="Share Athlete"
                                >
                                  <div className="manageAthletesShareAthleteButtonIcon" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="manageAthletesAthleteListColumn shared-coaches">
                            <div className="manageAthletesSharedCoaches" />
                          </div>
                        </div>
                        <div className="manageAthletesAthleteListRowSmallScreen">
                          {/* <div className="manageAthletesAthleteListRowSmallScreenRow">
                            <div className="manageAthletesAthleteListRowSmallScreenColumn">
                              <div className="manageAthletesAthleteListRowProfile">
                                <input type="checkbox" />
                                <div className="manageAthletesAthleteProfile canSelect">
                                  <div className="athleteAvatar">
                                    <div className="athleteAvatarBasicAthlete">
                                      <div className="nophoto avatar" />
                                    </div>
                                  </div>
                                  <span>ava andrews</span>
                                </div>
                              </div>
                              <div className="manageAthletesAccountType">
                                <span className="manageAthletesAccountTypeLabel">
                                  Basic
                                </span>
                                <div className="manageAthletesAccountTypeButton upgrade">
                                  <span>Upgrade</span>
                                </div>
                              </div>
                            </div>
                            <div className="manageAthletesAthleteListRowSmallScreenColumn selectOptions">
                              <div className="selectContainer">
                                <span className="selectLabel">
                                  Current Group
                                </span>
                                <select
                                  dir="ltr"
                                  className="  manageAthletesSelectBox no-background"
                                  data-tooltip=""
                                >
                                  <option value="44746">My Athletes</option>
                                  <option value="166840">
                                    10 kgs in 10 days{" "}
                                  </option>
                                  <option value="145507">
                                    12 wee difference program{" "}
                                  </option>
                                  <option value="74011">
                                    2 week free trial
                                  </option>
                                  <option value="75281">
                                    4 week free trial{" "}
                                  </option>
                                  <option value="61261">
                                    Aaron Buchan's Coached Crew
                                  </option>
                                  <option value="127935">
                                    Elite riders 2020 MSA
                                  </option>
                                  <option value="163619">M15 program </option>
                                  <option value="79857">
                                    MOtor cycling AU{" "}
                                  </option>
                                  <option value="61956">
                                    Multisport athletes on plans
                                  </option>
                                  <option value="104435">Never started </option>
                                  <option value="61262">Online coaching</option>
                                  <option value="66459">Robs Clients </option>
                                  <option value="61266">
                                    Swim coaching members
                                  </option>
                                  <option value="85241">
                                    multisport athletes off program ATM{" "}
                                  </option>
                                  <option value="61267">
                                    multisport leads{" "}
                                  </option>
                                  <option value="101225">
                                    previous members{" "}
                                  </option>
                                </select>
                              </div>
                              <div className="selectContainer">
                                <span className="selectLabel">
                                  Current Coach
                                </span>
                                <div>
                                  <select
                                    dir="ltr"
                                    className="  manageAthletesSelectBox no-background"
                                    data-tooltip=""
                                  >
                                    <option value="1721548">
                                      Aaron Buchan
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="manageAthletesAthleteListRowSmallScreenColumn actions">
                              <div className="manageAthleteAthleteActions">
                                <div
                                  className="manageAthletesAthleteSettings"
                                  data-tooltip="Open athlete settings"
                                >
                                  <div className="manageAthletesAthleteSettingsIcon" />
                                </div>
                                <div className="manageAthletesRemoveAthlete">
                                  <div
                                    className="manageAthletesRemoveAthleteButton"
                                    data-tooltip="Remove Athlete"
                                  >
                                    <div className="manageAthletesRemoveAthleteButtonIcon" />
                                  </div>
                                </div>
                                <div className="manageAthletesShareAthlete">
                                  <div
                                    className="manageAthletesShareAthleteButton"
                                    data_cy="share"
                                    data-tooltip="Share Athlete"
                                  >
                                    <div className="manageAthletesShareAthleteButtonIcon" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="manageAthletesSpacer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageAthletes;
