import React from "react";
import propng from "../Images/propng.png";
import buchanIcon from "../Images/buchanIcon.jpg";
import clock from "../Images/clock.png";
import comment_6 from "../Images/comment_6.png";
import speedMeter from "../Images/speedMeter.png";
import markIcon from "../Images/markIcon.jpg";
import alerticon from "../Images/alerticon.png";
import alertRed from "../Images/alertRed.png";
import Header from "../component/DashboardCom/Header";
import alertRxxed from "../Images/strength-icon-4.png";
import aTGB from "../Images/img_453995.png";
import SideBar from "../Dashboard/SideBar/Index";



const Dashboard = () => {
  let arr = [
    {
      id: 1,
    },
    {
      id: 5,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 5,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 5,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 5,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
  ];

  return (
    <>
      <Header />
      <div className="flexContent">
       
       <SideBar/>

        <div className="rightRow">
          <div className="appContainerMainContentContainer">
            <div className="appContainerMainContentContainerScrollContainer">
              <div className="row frameworkHeader dashboardHeader">
                <div className="frameworkHeaderView">
                  <div
                    id="calendarMonthLabel"
                    className="calendarMonthLabel frameworkTitle"
                    title="Select Date"
                  >
                    <label className="headerMonth">10 July, 2023 - </label>
                    <label className="headerYear">28 October, 2023</label>
                  </div>
                  {/* <div className="navigation">
                    <div className="printRefreshButtons">
                      <button
                        className="refreshButton"
                        title="Refresh"
                        tabIndex={-1}
                      />
                    </div>
                  </div> */}

                  <div className="athleteInfoContainer">
                    <div className="athleteInformation">
                      <div className="photo nophoto" style={{}} />
                      <div style={{ float: "left" }}>
                        <h2 className="athleteName clickable">ava andrews</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frameworkMainWrapper">
                <div className="frameworkScrollableContainer frameworkMainContentWell dashboardContainer">
                  <div className="dashboardWrapper">
                    <div className="primaryContainerView dashboardChartsContainerView">
                      <div className="scrollHeader" />
                      {/* TODO: Remove ID, currently styles seem to depend on it */}
                      <div
                        className="scrollable chartsRegion"
                        id="chartsContainer"
                      >
                        <div
                          className="packeryCollection ui-droppable"
                          style={{ position: "relative", height: 4390 }}
                        >
                          <div
                            className="dashboardChart workoutSummaryChart ui-draggable noData"
                            style={{ position: "absolute", left: 0, top: 0 }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Distance by Week: Swim
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart workoutSummaryChart ui-draggable noData"
                            style={{ position: "absolute", left: 400, top: 0 }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Duration by Week: Bike
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart fitnessSummaryChart ui-draggable noData"
                            style={{ position: "absolute", left: 800, top: 0 }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Fitness Summary: Completed Duration
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart fitnessSummaryChart ui-draggable noData"
                            style={{ position: "absolute", left: 0, top: 400 }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Fitness Summary: Completed Distance
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart timeInZones ui-draggable noData"
                            style={{
                              position: "absolute",
                              left: 400,
                              top: 400,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Time In Speed Zones by week: All Workout Types
                            </div>
                            <div className="customDateRange">Last 180 days</div>
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart fitnessSummaryChart ui-draggable noData"
                            style={{
                              position: "absolute",
                              left: 800,
                              top: 400,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Fitness Summary: Planned Duration
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart fitnessSummaryChart ui-draggable noData"
                            style={{ position: "absolute", left: 0, top: 800 }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Fitness Summary: Planned Distance
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart workoutSummaryChart ui-draggable"
                            style={{
                              position: "absolute",
                              left: 400,
                              top: 800,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div
                              className="chartContainer"
                              style={{ padding: 0 }}
                            >
                              <canvas
                                className="flot-base"
                                width="330"
                                height="297"
                                style={{
                                  direction: "ltr",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                  width: 330,
                                  height: 297,
                                }}
                              />
                              <div
                                className="flot-text"
                                style={{
                                  position: "absolute",
                                  inset: 0,
                                  fontSize: "smaller",
                                  color: "rgb(84, 84, 84)",
                                }}
                              >
                                <div
                                  className="flot-x-axis flot-x1-axis xAxis x1Axis"
                                  style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "block",
                                  }}
                                >
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      maxWidth: 55,
                                      top: 289,
                                      left: 81,
                                      textAlign: "center",
                                    }}
                                  >
                                    31/7/2023
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      maxWidth: 55,
                                      top: 289,
                                      left: 152,
                                      textAlign: "center",
                                    }}
                                  >
                                    28/8/2023
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      maxWidth: 55,
                                      top: 289,
                                      left: 222,
                                      textAlign: "center",
                                    }}
                                  >
                                    25/9/2023
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      maxWidth: 55,
                                      top: 289,
                                      left: 290,
                                      textAlign: "center",
                                    }}
                                  >
                                    23/10/2023
                                  </div>
                                </div>
                                <div
                                  className="flot-y-axis flot-y1-axis yAxis y1Axis"
                                  style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "block",
                                  }}
                                >
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 273,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0:00:00
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 218,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0:12:00
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 164,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0:24:00
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 109,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0:36:00
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 55,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0:48:00
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 1,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    1:00:00
                                  </div>
                                </div>
                              </div>
                              <canvas
                                className="flot-overlay"
                                width="330"
                                height="297"
                                style={{
                                  direction: "ltr",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                  width: 330,
                                  height: 297,
                                }}
                              />
                            </div>
                            <div className="chartTitle">
                              Longest Workout (Duration): All Workout Types
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue">
                              Average: 0:00:00{" "}
                            </div>
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart pmcChart ui-draggable"
                            style={{ position: "absolute", left: 0, top: 1200 }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartTitle pmc">
                              Performance Management - Workout Type: All Workout
                              Types
                            </div>
                            <div className="customDateRange pmc">
                              Last 365 days
                            </div>
                            <div className="yaxisLabel pmc tssd">TSS/d</div>
                            <div className="yaxisLabel pmc tsb">Form (TSB)</div>
                            <div
                              className="chartContainer"
                              style={{ padding: 0 }}
                            >
                              <canvas
                                className="flot-base"
                                width="730"
                                height="297"
                                style={{
                                  direction: "ltr",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                  width: 730,
                                  height: 297,
                                }}
                              />
                              <div
                                className="flot-text"
                                style={{
                                  position: "absolute",
                                  inset: 0,
                                  fontSize: "smaller",
                                  color: "rgb(84, 84, 84)",
                                }}
                              >
                                <div
                                  className="flot-x-axis flot-x1-axis xAxis x1Axis"
                                  style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "block",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      maxWidth: 91,
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(99, 101, 105)",
                                      top: 288,
                                      left: 114,
                                      textAlign: "center",
                                    }}
                                  >
                                    2/12/2022
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      maxWidth: 91,
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(99, 101, 105)",
                                      top: 288,
                                      left: 216,
                                      textAlign: "center",
                                    }}
                                  >
                                    29/1/2023
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      maxWidth: 91,
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(99, 101, 105)",
                                      top: 288,
                                      left: 319,
                                      textAlign: "center",
                                    }}
                                  >
                                    28/3/2023
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      maxWidth: 91,
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(99, 101, 105)",
                                      top: 288,
                                      left: 421,
                                      textAlign: "center",
                                    }}
                                  >
                                    25/5/2023
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      maxWidth: 91,
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(99, 101, 105)",
                                      top: 288,
                                      left: 523,
                                      textAlign: "center",
                                    }}
                                  >
                                    22/7/2023
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      maxWidth: 91,
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(99, 101, 105)",
                                      top: 288,
                                      left: 625,
                                      textAlign: "center",
                                    }}
                                  >
                                    18/9/2023
                                  </div>
                                </div>
                                <div
                                  className="flot-y-axis flot-y1-axis yAxis y1Axis"
                                  style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "block",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(99, 101, 105)",
                                      top: 274,
                                      left: 22,
                                      textAlign: "right",
                                    }}
                                  >
                                    0
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(99, 101, 105)",
                                      top: 3,
                                      left: 22,
                                      textAlign: "right",
                                    }}
                                  >
                                    1
                                  </div>
                                </div>
                                <div
                                  className="flot-y-axis flot-y2-axis yAxis y2Axis"
                                  style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "block",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(51, 153, 255)",
                                      top: 274,
                                      left: 691,
                                    }}
                                  >
                                    0.0
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(51, 153, 255)",
                                      top: 220,
                                      left: 691,
                                    }}
                                  >
                                    0.2
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(51, 153, 255)",
                                      top: 166,
                                      left: 691,
                                    }}
                                  >
                                    0.4
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(51, 153, 255)",
                                      top: 111,
                                      left: 691,
                                    }}
                                  >
                                    0.6
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(51, 153, 255)",
                                      top: 57,
                                      left: 691,
                                    }}
                                  >
                                    0.8
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(51, 153, 255)",
                                      top: 3,
                                      left: 691,
                                    }}
                                  >
                                    1.0
                                  </div>
                                </div>
                                <div
                                  className="flot-y-axis flot-y3-axis yAxis y3Axis"
                                  style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "block",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(185, 8, 7)",
                                      top: 274,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(185, 8, 7)",
                                      top: 3,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    1
                                  </div>
                                </div>
                                <div
                                  className="flot-y-axis flot-y4-axis yAxis y4Axis"
                                  style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "block",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(255, 165, 0)",
                                      top: 139,
                                      left: 718,
                                    }}
                                  >
                                    0
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(255, 165, 0)",
                                      top: 71,
                                      left: 718,
                                    }}
                                  >
                                    10
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(255, 165, 0)",
                                      top: 3,
                                      left: 718,
                                    }}
                                  >
                                    20
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(255, 165, 0)",
                                      top: 274,
                                      left: 718,
                                    }}
                                  >
                                    -20
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      font: "400 9px / 10px HelveticaNeueW01-55Roma",
                                      color: "rgb(255, 165, 0)",
                                      top: 206,
                                      left: 718,
                                    }}
                                  >
                                    -10
                                  </div>
                                </div>
                              </div>
                              <canvas
                                className="flot-overlay"
                                width="730"
                                height="297"
                                style={{
                                  direction: "ltr",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                  width: 730,
                                  height: 297,
                                }}
                              />
                            </div>
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart workoutSummaryChart ui-draggable"
                            style={{
                              position: "absolute",
                              left: 800,
                              top: 800,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div
                              className="chartContainer"
                              style={{ padding: 0 }}
                            >
                              <canvas
                                className="flot-base"
                                width="330"
                                height="297"
                                style={{
                                  direction: "ltr",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                  width: 330,
                                  height: 297,
                                }}
                              />
                              <div
                                className="flot-text"
                                style={{
                                  position: "absolute",
                                  inset: 0,
                                  fontSize: "smaller",
                                  color: "rgb(84, 84, 84)",
                                }}
                              >
                                <div
                                  className="flot-x-axis flot-x1-axis xAxis x1Axis"
                                  style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "block",
                                  }}
                                >
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      maxWidth: 55,
                                      top: 289,
                                      left: 72,
                                      textAlign: "center",
                                    }}
                                  >
                                    31/7/2023
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      maxWidth: 55,
                                      top: 289,
                                      left: 145,
                                      textAlign: "center",
                                    }}
                                  >
                                    28/8/2023
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      maxWidth: 55,
                                      top: 289,
                                      left: 219,
                                      textAlign: "center",
                                    }}
                                  >
                                    25/9/2023
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      maxWidth: 55,
                                      top: 289,
                                      left: 290,
                                      textAlign: "center",
                                    }}
                                  >
                                    23/10/2023
                                  </div>
                                </div>
                                <div
                                  className="flot-y-axis flot-y1-axis yAxis y1Axis"
                                  style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "block",
                                  }}
                                >
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 273,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0.00
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 218,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0.00
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 164,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0.00
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 109,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0.00
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 55,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0.00
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 1,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0.00
                                  </div>
                                </div>
                              </div>
                              <canvas
                                className="flot-overlay"
                                width="330"
                                height="297"
                                style={{
                                  direction: "ltr",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                  width: 330,
                                  height: 297,
                                }}
                              />
                            </div>
                            <div className="chartTitle">
                              Longest Workout (Distance): All Workout Types
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue">
                              Average: 0.00 km
                            </div>
                            <div className="yaxisLabel left">km</div>
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart workoutSummaryChart ui-draggable noData"
                            style={{
                              position: "absolute",
                              left: 800,
                              top: 1200,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Elevation Gain by Week: All Workout Types
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart timeInZones ui-draggable noData"
                            style={{ position: "absolute", left: 0, top: 1600 }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Time In Heart Rate Zones by week: All Workout
                              Types
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart timeInZones ui-draggable noData"
                            style={{
                              position: "absolute",
                              left: 400,
                              top: 1600,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Time In Power Zones by week: All Workout Types
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart fitnessHistory ui-draggable"
                            style={{ position: "absolute", left: 0, top: 2000 }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="podContent">
                              <table className="frameworkTable">
                                <thead>
                                  <tr>
                                    <th>Week of</th>
                                    <th>Duration</th>
                                    <th>Distance (km)</th>
                                    <th>TSS</th>

                                    <th>5s</th>
                                    <th>1m</th>
                                    <th>5m</th>
                                    <th>20m</th>
                                    <th>60m</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    className="incomplete"
                                    data-start-date="2023-10-02T00:00:00"
                                  >
                                    <td>2/10/2023</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-09-25T00:00:00">
                                    <td>25/9/2023</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-09-18T00:00:00">
                                    <td>18/9/2023</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-09-11T00:00:00">
                                    <td>11/9/2023</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>
                                </tbody>
                                <thead>
                                  <tr>
                                    <th>Recent Months</th>
                                    <th colSpan="9" />
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    className="incomplete"
                                    data-start-date="2023-10-01T00:00:00"
                                  >
                                    <td>October '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-09-01T00:00:00">
                                    <td>September '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-08-01T00:00:00">
                                    <td>August '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-07-01T00:00:00">
                                    <td>July '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-06-01T00:00:00">
                                    <td>June '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-05-01T00:00:00">
                                    <td>May '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-04-01T00:00:00">
                                    <td>April '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-03-01T00:00:00">
                                    <td>March '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-02-01T00:00:00">
                                    <td>February '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-01-01T00:00:00">
                                    <td>January '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2022-12-01T00:00:00">
                                    <td>December '22</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2022-11-01T00:00:00">
                                    <td>November '22</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2022-10-01T00:00:00">
                                    <td>October '22</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>

                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="podTitle">
                              Fitness History: Peak Heart Rate (bpm) - Run
                            </div>
                          </div>
                          <div
                            className="dashboardChart fitnessHistory ui-draggable"
                            style={{ position: "absolute", left: 0, top: 2400 }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="podContent">
                              <table className="frameworkTable">
                                <thead>
                                  <tr>
                                    <th>Week of</th>
                                    <th>Duration</th>
                                    <th>Distance (km)</th>
                                    <th>TSS</th>
                                    <th data-peaktype="4">kJ</th>

                                    <th>5s</th>
                                    <th>1m</th>
                                    <th>5m</th>
                                    <th>20m</th>
                                    <th>60m</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    className="incomplete"
                                    data-start-date="2023-10-02T00:00:00"
                                  >
                                    <td>2/10/2023</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-09-25T00:00:00">
                                    <td>25/9/2023</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-09-18T00:00:00">
                                    <td>18/9/2023</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-09-11T00:00:00">
                                    <td>11/9/2023</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>
                                </tbody>
                                <thead>
                                  <tr>
                                    <th>Recent Months</th>
                                    <th colSpan="9" />
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    className="incomplete"
                                    data-start-date="2023-10-01T00:00:00"
                                  >
                                    <td>October '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-09-01T00:00:00">
                                    <td>September '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-08-01T00:00:00">
                                    <td>August '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-07-01T00:00:00">
                                    <td>July '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-06-01T00:00:00">
                                    <td>June '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-05-01T00:00:00">
                                    <td>May '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-04-01T00:00:00">
                                    <td>April '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-03-01T00:00:00">
                                    <td>March '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-02-01T00:00:00">
                                    <td>February '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2023-01-01T00:00:00">
                                    <td>January '23</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2022-12-01T00:00:00">
                                    <td>December '22</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2022-11-01T00:00:00">
                                    <td>November '22</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>

                                  <tr data-start-date="2022-10-01T00:00:00">
                                    <td>October '22</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td data-peaktype="4">--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="podTitle">
                              Fitness History: Peak Power (W) - All Workout
                              Types
                            </div>
                          </div>
                          <div
                            className="dashboardChart timeInZones ui-draggable noData"
                            style={{
                              position: "absolute",
                              left: 800,
                              top: 1600,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Time In Speed Zones by week: All Workout Types
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart timeInZones ui-draggable noData"
                            style={{
                              position: "absolute",
                              left: 800,
                              top: 2000,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Time In Heart Rate Zones: Run
                            </div>
                            <div className="customDateRange">Last 28 days</div>
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart timeInZones ui-draggable noData"
                            style={{
                              position: "absolute",
                              left: 800,
                              top: 2400,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Time In Power Zones: All Workout Types
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart timeInZones ui-draggable noData"
                            style={{ position: "absolute", left: 0, top: 2800 }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Time In Speed Zones: All Workout Types
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No workouts are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart metricsChart ui-draggable noData"
                            style={{
                              position: "absolute",
                              left: 400,
                              top: 2800,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">Metrics</div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No metrics are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart metricsChart ui-draggable noData"
                            style={{
                              position: "absolute",
                              left: 800,
                              top: 2800,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">Metrics</div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No metrics are available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart peaksChart ui-draggable"
                            style={{ position: "absolute", left: 0, top: 3200 }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartTitle">
                              Peak Heart Rate: All Workout Types
                            </div>
                            <div className="customDateRange" />
                            <div
                              className="avgChartValue"
                              style={{ display: "none" }}
                            />
                            <div className="yaxisLabel left">bpm</div>
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div
                              className="chartContainer"
                              style={{ padding: 0 }}
                            >
                              <canvas
                                className="flot-base"
                                width="330"
                                height="297"
                                style={{
                                  direction: "ltr",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                  width: 330,
                                  height: 297,
                                }}
                              />
                              <div
                                className="flot-text"
                                style={{
                                  position: "absolute",
                                  inset: 0,
                                  fontSize: "smaller",
                                  color: "rgb(84, 84, 84)",
                                }}
                              >
                                <div
                                  className="flot-x-axis flot-x1-axis xAxis x1Axis"
                                  style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "block",
                                  }}
                                />
                                <div
                                  className="flot-y-axis flot-y1-axis yAxis y1Axis"
                                  style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "block",
                                  }}
                                >
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 273,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 205,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 137,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    0
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 69,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    1
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 1,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    1
                                  </div>
                                </div>
                              </div>
                              <canvas
                                className="flot-overlay"
                                width="330"
                                height="297"
                                style={{
                                  direction: "ltr",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                  width: 330,
                                  height: 297,
                                }}
                              />
                              <div className="legend">
                                <div
                                  style={{
                                    position: "absolute",
                                    width: 140,
                                    height: 14,
                                    top: 13,
                                    right: 20,
                                    backgroundColor: "rgb(255, 255, 255)",
                                    opacity: "0.65",
                                  }}
                                >
                                  {" "}
                                </div>
                                <table
                                  style={{
                                    position: "absolute",
                                    top: 13,
                                    right: 20,
                                    fontSize: "smaller",
                                    color: "#545454",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td className="legendColorBox">
                                        <div
                                          style={{
                                            border: "1px solid null",
                                            padding: 1,
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: 4,
                                              height: "0",
                                              border:
                                                "5px solid rgba(184, 36, 16, 0.6)",
                                              overflow: "hidden",
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td className="legendLabel">
                                        10/7/2023 - 28/10/2023
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div
                              className="podContent"
                              style={{ display: "none" }}
                            />
                            <div className="noDataMessage">
                              No data to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart peaksChart ui-draggable noData"
                            style={{
                              position: "absolute",
                              left: 400,
                              top: 3200,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartTitle">
                              Peak Power: All Workout Types
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="chartContainer" />
                            <div
                              className="podContent"
                              style={{ display: "none" }}
                            />
                            <div className="noDataMessage">
                              No data to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart peaksChart ui-draggable"
                            style={{
                              position: "absolute",
                              left: 800,
                              top: 3200,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartTitle">
                              Peak Pace by Distance: Run
                            </div>
                            <div className="customDateRange" />
                            <div
                              className="avgChartValue"
                              style={{ display: "none" }}
                            />
                            <div className="yaxisLabel left">min/km</div>
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div
                              className="chartContainer"
                              style={{ padding: 0 }}
                            >
                              <canvas
                                className="flot-base"
                                width="330"
                                height="297"
                                style={{
                                  direction: "ltr",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                  width: 330,
                                  height: 297,
                                }}
                              />
                              <div
                                className="flot-text"
                                style={{
                                  position: "absolute",
                                  inset: 0,
                                  fontSize: "smaller",
                                  color: "rgb(84, 84, 84)",
                                }}
                              >
                                <div
                                  className="flot-x-axis flot-x1-axis xAxis x1Axis"
                                  style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "block",
                                  }}
                                />
                                <div
                                  className="flot-y-axis flot-y1-axis yAxis y1Axis"
                                  style={{
                                    position: "absolute",
                                    inset: 0,
                                    display: "block",
                                  }}
                                >
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 273,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    00:01
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 205,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    00:01
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 69,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    33:20
                                  </div>
                                  <div
                                    className="flot-tick-label tickLabel"
                                    style={{
                                      position: "absolute",
                                      top: 1,
                                      left: "-1px",
                                      textAlign: "right",
                                    }}
                                  >
                                    16:40
                                  </div>
                                </div>
                              </div>
                              <canvas
                                className="flot-overlay"
                                width="330"
                                height="297"
                                style={{
                                  direction: "ltr",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                  width: 330,
                                  height: 297,
                                }}
                              />
                              <div className="legend">
                                <div
                                  style={{
                                    position: "absolute",
                                    width: 140,
                                    height: 14,
                                    top: 13,
                                    right: 20,
                                    backgroundColor: "rgb(255, 255, 255)",
                                    opacity: "0.65",
                                  }}
                                >
                                  {" "}
                                </div>
                                <table
                                  style={{
                                    position: "absolute",
                                    top: 13,
                                    right: 20,
                                    fontSize: "smaller",
                                    color: "#545454",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td className="legendColorBox">
                                        <div
                                          style={{
                                            border: "1px solid null",
                                            padding: 1,
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: 4,
                                              height: "0",
                                              border:
                                                "5px solid rgba(18, 86, 137, 0.6)",
                                              overflow: "hidden",
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td className="legendLabel">
                                        10/7/2023 - 28/10/2023
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div
                              className="podContent"
                              style={{ display: "none" }}
                            />
                            <div className="noDataMessage">
                              No data to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart macronutrientsbyTimePeriod ui-draggable noData"
                            style={{ position: "absolute", left: 0, top: 3600 }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Macronutrients by Week
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No nutrition data is available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart caloriesByTimePeriod ui-draggable noData"
                            style={{
                              position: "absolute",
                              left: 400,
                              top: 3600,
                            }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">Calories by Week</div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              No nutrition data is available to report on
                            </div>
                          </div>
                          <div
                            className="dashboardChart powerProfile ui-draggable noData"
                            style={{ position: "absolute", left: 0, top: 4000 }}
                          >
                            <div className="chartButtons">
                              <div className="settings" />
                              <div className="expand" />
                              <div className="collapse" />
                              <div className="close" />
                            </div>
                            <div className="chartContainer" />
                            <div className="chartTitle">
                              Power Profile (Cycling): Single Group
                            </div>
                            <div className="customDateRange" />
                            <div className="avgChartValue" />
                            <div className="yaxisLabel left" />
                            <div className="yaxisLabel right" />
                            <div className="xaxisLabel" />
                            <div className="noDataMessage">
                              There is no power data for the selected date range
                              to report on, or you may need to enter your body
                              weight.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="quickViewContainer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
