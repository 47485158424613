import React from "react";
import propng from "../Images/propng.png";
import buchanIcon from "../Images/buchanIcon.jpg";
import clock from "../Images/clock.png";
import comment_6 from "../Images/comment_6.png";
import speedMeter from "../Images/speedMeter.png";
import markIcon from "../Images/markIcon.jpg";
import alerticon from "../Images/alerticon.png";
import alertRed from "../Images/alertRed.png";
import Header from "../component/DashboardCom/Header";

const Calender = () => {
  let arr = [
    {
      id: 1,
    },
    {
      id: 5,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 5,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 5,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 5,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
  ];

  return (
    <>
      <Header />
      <div className="flexContent">
        <div className="SideRow">
          <button>
            <div className="iconthis">
              <img src={propng} alt="" />
              <div className="libraryTitle">Athlete Library</div>
            </div>
          </button>
        </div>

        <div className="rightRow">
          <div className="appContainerMainContentContainer">
            <div className="appContainerMainContentContainerScrollContainer">
              <div data_cy="calendarHeader">
                <div
                  className="calendarNavigation"
                  data_cy="calendarHeaderView"
                >
                  <div className="calendarSubNavigation athleteCalendarNavigation">
                    <div className="calendarNavigationLeftControls">
                      <div className="calendarDatePicker">
                        <label className="shortMonth">Jan 2023</label>
                        <label className="fullMonth">January 2023</label>
                        <div className="caretImageContainer">
                          <div className="caretImageDate" />
                        </div>
                        <input
                          type="text"
                          tabIndex={-1}
                          className="datePicker datepicker hasDatepicker"
                          data-tooltip="Select Date"
                          value="2023-01-16"
                          id="dp1696026373249"
                        />
                      </div>
                      <div className="calendarDateNavigation">
                        <button
                          className="goToTodayButton"
                          data-tooltip="Go to Today"
                          tabIndex={-1}
                        >
                          Today
                        </button>
                        <div
                          className="weekArrowButton"
                          tabIndex={-1}
                          data-tooltip="Back One Week"
                        >
                          <div className="weekArrowBackButton" />
                        </div>
                        <div
                          className="weekArrowButton"
                          tabIndex={-1}
                          data-tooltip="Forward One Week"
                        >
                          <div className="weekArrowForwardButton" />
                        </div>
                      </div>
                    </div>
                    <div className="calendarNavigationCenterControls">
                      <div
                        className="groupAndAthleteSelector"
                        data_cy="tomahawkButton"
                        tabIndex={0}
                      >
                        <div className="selectedAthleteContainer">
                          <div className="isBasicAthlete athleteSelectorPhoto clickable nophoto" />
                          <div className="selectedAthleteName">
                            <span>ava andrews</span>
                          </div>
                          <div className="caretImage" />
                        </div>
                        <div
                          className="openAthleteSettingsButton"
                          data-tooltip="ava  andrews Athlete Settings"
                        >
                          <div className="openAthleteSettingsButtonIcon" />
                        </div>
                      </div>
                    </div>
                    <div className="calendarNavigationRightControls">
                      <div className="dualCalendarControls">
                        <div
                          className="dualCalendarControlsButton"
                          data-tooltip="Open Dual Calendar"
                          tabIndex={-1}
                        />
                      </div>
                      <div
                        className="refreshCalendarButton"
                        data-tooltip="Refresh"
                        tabIndex={-1}
                      >
                        <div className="refreshCalendarButtonIcon" />
                      </div>
                      <div
                        className="workoutSearch"
                        data-tooltip="Search Workouts"
                      >
                        <div className="calendarNavigationWorkoutSearchIcon" />
                      </div>
                      <div
                        className="calendarSettings"
                        tabIndex={0}
                        data-tooltip="Calendar Settings"
                      >
                        <div className="calendarSettingsIcon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frameworkMainWrapper">
                <div className="frameworkScrollableContainer frameworkMainContentWell calendarContainer">
                  <div className="calendarContainer">
                    <div className="calendars">
                      <div
                        className="calendar athleteCalendar STANDARD SINGLE showComplianceColoring"
                        data_cy="athleteCalendar"
                      >
                        <div className="weekHeader">
                          <div
                            className="dayNames cf"
                            style={{ paddingRight: 17 }}
                          >
                            <div className="dayWidth dayName" droppable="true">
                              <p>Mon</p>
                            </div>
                            <div className="dayWidth dayName" droppable="true">
                              <p>Tue</p>
                            </div>
                            <div className="dayWidth dayName" droppable="true">
                              <p>Wed</p>
                            </div>
                            <div className="dayWidth dayName" droppable="true">
                              <p>Thu</p>
                            </div>
                            <div className="dayWidth dayName" droppable="true">
                              <p>Fri</p>
                            </div>
                            <div className="dayWidth dayName" droppable="true">
                              <p>Sat</p>
                            </div>
                            <div className="dayWidth dayName" droppable="true">
                              <p>Sun</p>
                            </div>
                            <div className="summaryWidth dayName">
                              <div className="summaryToggleButton">
                                <div className="summaryToggleIcon" />
                              </div>
                              <p>Summary</p>
                            </div>
                          </div>
                        </div>
                        <div className="contents scrollable">
                          <div className="weeksBeforeVisibleWeeks">
                            <div
                              className="calendarWeekContainer"
                              data_cy="calendarWeekContainer"
                              data-date="2022-10-31"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-10-31"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">31</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-11-01"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">1 Nov</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-11-02"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">2</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-11-03"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">3</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-11-04"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">4</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-11-05"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">5</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-11-06"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">6</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                             
                            <div
                              className="calendarWeekContainer"
                              data_cy="calendarWeekContainer"
                              data-date="2022-12-19"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-12-19"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">19</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-12-20"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">20</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-12-21"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">21</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-12-22"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">22</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-12-23"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">23</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-12-24"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">24</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-12-25"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">25</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="performance">
                                      <div className="stat fitness CTL">
                                        <div className="label">Fitness</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">CTL</div>
                                        </div>
                                      </div>
                                      <div className="stat fatigue ATL">
                                        <div className="label">Fatigue</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">ATL</div>
                                        </div>
                                      </div>
                                      <div className="stat form TSB">
                                        <div className="label">Form</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">TSB</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary">
                                        <div className="statsRow total">
                                          <div>
                                            <label>Duration</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">00:00</span>
                                            <span className="units">hms</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Distance</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0.00</span>
                                            <span className="units">km</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Training Stress Score</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0</span>
                                            <span className="units">TSS</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="calendarWeekContainer"
                              data_cy="calendarWeekContainer"
                              data-date="2022-12-26"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-12-26"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">26</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-12-27"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">27</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-12-28"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">28</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-12-29"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">29</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-12-30"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">30</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2022-12-31"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">31</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-01"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">1 Jan 2023</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="performance">
                                      <div className="stat fitness CTL">
                                        <div className="label">Fitness</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">CTL</div>
                                        </div>
                                      </div>
                                      <div className="stat fatigue ATL">
                                        <div className="label">Fatigue</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">ATL</div>
                                        </div>
                                      </div>
                                      <div className="stat form TSB">
                                        <div className="label">Form</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">TSB</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary">
                                        <div className="statsRow total">
                                          <div>
                                            <label>Duration</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">00:00</span>
                                            <span className="units">hms</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Distance</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0.00</span>
                                            <span className="units">km</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Training Stress Score</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0</span>
                                            <span className="units">TSS</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="calendarWeekContainer"
                              data_cy="calendarWeekContainer"
                              data-date="2023-01-02"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-02"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">2</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-03"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">3</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-04"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">4</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-05"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">5</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-06"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">6</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-07"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">7</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-08"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">8</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="performance">
                                      <div className="stat fitness CTL">
                                        <div className="label">Fitness</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">CTL</div>
                                        </div>
                                      </div>
                                      <div className="stat fatigue ATL">
                                        <div className="label">Fatigue</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">ATL</div>
                                        </div>
                                      </div>
                                      <div className="stat form TSB">
                                        <div className="label">Form</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">TSB</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary">
                                        <div className="statsRow total">
                                          <div>
                                            <label>Duration</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">00:00</span>
                                            <span className="units">hms</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Distance</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0.00</span>
                                            <span className="units">km</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Training Stress Score</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0</span>
                                            <span className="units">TSS</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="calendarWeekContainer"
                              data_cy="calendarWeekContainer"
                              data-date="2023-01-09"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-09"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">9</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-10"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">10</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-11"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">11</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-12"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">12</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-13"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">13</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-14"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">14</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-15"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">15</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="performance">
                                      <div className="stat fitness CTL">
                                        <div className="label">Fitness</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">CTL</div>
                                        </div>
                                      </div>
                                      <div className="stat fatigue ATL">
                                        <div className="label">Fatigue</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">ATL</div>
                                        </div>
                                      </div>
                                      <div className="stat form TSB">
                                        <div className="label">Form</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">TSB</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary">
                                        <div className="statsRow total">
                                          <div>
                                            <label>Duration</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">00:00</span>
                                            <span className="units">hms</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Distance</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0.00</span>
                                            <span className="units">km</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Training Stress Score</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0</span>
                                            <span className="units">TSS</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="focusedWeeks" data_cy="focusedWeeks">
                            <div
                              className="calendarWeekContainer inView"
                              data_cy="calendarWeekContainer"
                              data-date="2023-01-16"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-16"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">16</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-17"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">17</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-18"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">18</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-19"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">19</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-20"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">20</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-21"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">21</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-22"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">22</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="performance">
                                      <div className="stat fitness CTL">
                                        <div className="label">Fitness</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">CTL</div>
                                        </div>
                                      </div>
                                      <div className="stat fatigue ATL">
                                        <div className="label">Fatigue</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">ATL</div>
                                        </div>
                                      </div>
                                      <div className="stat form TSB">
                                        <div className="label">Form</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">TSB</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary">
                                        <div className="statsRow total">
                                          <div>
                                            <label>Duration</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">00:00</span>
                                            <span className="units">hms</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Distance</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0.00</span>
                                            <span className="units">km</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Training Stress Score</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0</span>
                                            <span className="units">TSS</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="calendarWeekContainer inView"
                              data_cy="calendarWeekContainer"
                              data-date="2023-01-23"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-23"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">23</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-24"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">24</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-25"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">25</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-26"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">26</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-27"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">27</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-28"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">28</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-29"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">29</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="performance">
                                      <div className="stat fitness CTL">
                                        <div className="label">Fitness</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">CTL</div>
                                        </div>
                                      </div>
                                      <div className="stat fatigue ATL">
                                        <div className="label">Fatigue</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">ATL</div>
                                        </div>
                                      </div>
                                      <div className="stat form TSB">
                                        <div className="label">Form</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">TSB</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary">
                                        <div className="statsRow total">
                                          <div>
                                            <label>Duration</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">00:00</span>
                                            <span className="units">hms</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Distance</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0.00</span>
                                            <span className="units">km</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Training Stress Score</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0</span>
                                            <span className="units">TSS</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="calendarWeekContainer"
                              data_cy="calendarWeekContainer"
                              data-date="2023-01-30"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-30"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">30</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-01-31"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">31</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-01"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">1 Feb</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-02"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">2</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-03"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">3</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-04"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">4</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-05"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">5</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="performance">
                                      <div className="stat fitness CTL">
                                        <div className="label">Fitness</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">CTL</div>
                                        </div>
                                      </div>
                                      <div className="stat fatigue ATL">
                                        <div className="label">Fatigue</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">ATL</div>
                                        </div>
                                      </div>
                                      <div className="stat form TSB">
                                        <div className="label">Form</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">TSB</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary">
                                        <div className="statsRow total">
                                          <div>
                                            <label>Duration</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">00:00</span>
                                            <span className="units">hms</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Distance</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0.00</span>
                                            <span className="units">km</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Training Stress Score</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0</span>
                                            <span className="units">TSS</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="calendarWeekContainer"
                              data_cy="calendarWeekContainer"
                              data-date="2023-02-06"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-06"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">6</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-07"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">7</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-08"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">8</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-09"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">9</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-10"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">10</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-11"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">11</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-12"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">12</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="performance">
                                      <div className="stat fitness CTL">
                                        <div className="label">Fitness</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">CTL</div>
                                        </div>
                                      </div>
                                      <div className="stat fatigue ATL">
                                        <div className="label">Fatigue</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">ATL</div>
                                        </div>
                                      </div>
                                      <div className="stat form TSB">
                                        <div className="label">Form</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">TSB</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary">
                                        <div className="statsRow total">
                                          <div>
                                            <label>Duration</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">00:00</span>
                                            <span className="units">hms</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Distance</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0.00</span>
                                            <span className="units">km</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Training Stress Score</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0</span>
                                            <span className="units">TSS</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="calendarWeekContainer"
                              data_cy="calendarWeekContainer"
                              data-date="2023-02-13"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-13"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">13</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-14"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">14</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-15"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">15</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-16"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">16</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-17"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">17</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-18"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">18</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-19"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">19</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="performance">
                                      <div className="stat fitness CTL">
                                        <div className="label">Fitness</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">CTL</div>
                                        </div>
                                      </div>
                                      <div className="stat fatigue ATL">
                                        <div className="label">Fatigue</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">ATL</div>
                                        </div>
                                      </div>
                                      <div className="stat form TSB">
                                        <div className="label">Form</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">TSB</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary">
                                        <div className="statsRow total">
                                          <div>
                                            <label>Duration</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">00:00</span>
                                            <span className="units">hms</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Distance</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0.00</span>
                                            <span className="units">km</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Training Stress Score</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0</span>
                                            <span className="units">TSS</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="calendarWeekContainer"
                              data_cy="calendarWeekContainer"
                              data-date="2023-02-20"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-20"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">20</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-21"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">21</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-22"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">22</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-23"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">23</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-24"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">24</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-25"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">25</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-26"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">26</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="performance">
                                      <div className="stat fitness CTL">
                                        <div className="label">Fitness</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">CTL</div>
                                        </div>
                                      </div>
                                      <div className="stat fatigue ATL">
                                        <div className="label">Fatigue</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">ATL</div>
                                        </div>
                                      </div>
                                      <div className="stat form TSB">
                                        <div className="label">Form</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">TSB</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary">
                                        <div className="statsRow total">
                                          <div>
                                            <label>Duration</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">00:00</span>
                                            <span className="units">hms</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Distance</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0.00</span>
                                            <span className="units">km</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Training Stress Score</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0</span>
                                            <span className="units">TSS</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="calendarWeekContainer"
                              data_cy="calendarWeekContainer"
                              data-date="2023-02-27"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-27"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">27</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-02-28"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">28</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-01"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">1 Mar</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-02"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">2</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-03"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">3</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-04"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">4</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-05"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">5</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="performance">
                                      <div className="stat fitness CTL">
                                        <div className="label">Fitness</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">CTL</div>
                                        </div>
                                      </div>
                                      <div className="stat fatigue ATL">
                                        <div className="label">Fatigue</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">ATL</div>
                                        </div>
                                      </div>
                                      <div className="stat form TSB">
                                        <div className="label">Form</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">TSB</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary">
                                        <div className="statsRow total">
                                          <div>
                                            <label>Duration</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">00:00</span>
                                            <span className="units">hms</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Distance</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0.00</span>
                                            <span className="units">km</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Training Stress Score</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0</span>
                                            <span className="units">TSS</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="calendarWeekContainer"
                              data_cy="calendarWeekContainer"
                              data-date="2023-03-06"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-06"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">6</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-07"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">7</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-08"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">8</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-09"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">9</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-10"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">10</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-11"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">11</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-12"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">12</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="performance">
                                      <div className="stat fitness CTL">
                                        <div className="label">Fitness</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">CTL</div>
                                        </div>
                                      </div>
                                      <div className="stat fatigue ATL">
                                        <div className="label">Fatigue</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">ATL</div>
                                        </div>
                                      </div>
                                      <div className="stat form TSB">
                                        <div className="label">Form</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">TSB</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary">
                                        <div className="statsRow total">
                                          <div>
                                            <label>Duration</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">00:00</span>
                                            <span className="units">hms</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Distance</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0.00</span>
                                            <span className="units">km</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Training Stress Score</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0</span>
                                            <span className="units">TSS</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="calendarWeekContainer"
                              data_cy="calendarWeekContainer"
                              data-date="2023-03-13"
                            >
                              <div className="cf daysContainer">
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-13"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">13</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-14"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">14</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-15"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">15</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-16"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">16</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-17"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">17</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-18"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">18</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="dayWidth dayContainer day"
                                  data_cy="dayContainer"
                                  draggable
                                  data-date="2023-03-19"
                                >
                                  <div
                                    className="dayNumberContainer"
                                    data_cy="dayNumberContainer"
                                  >
                                    <p className="dayNumberText">19</p>
                                    <div
                                      className="daySettings"
                                      data_cy="daySettings"
                                    >
                                      <button data_cy="daySettingsButton" />
                                    </div>
                                  </div>
                                  <div className="activities" />
                                  <div
                                    className="addWorkoutWrapper"
                                    data_cy="addWorkoutButton"
                                  >
                                    <div className="addWorkout">
                                      <div className="addWorkoutPlus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="meatBallsTower">
                                  <div
                                    className="meatBallsButton"
                                    tabIndex={0}
                                    data_cy="calendarWeekMeatBallButton"
                                    data-tooltip="Week Controls"
                                  >
                                    <div className="meatBallsIcon" />
                                  </div>
                                </div>
                                <div className="summaryWidth">
                                  <div className="summaryHeaderContainer" />
                                  <div className="weekSummaryContainer">
                                    <div className="performance">
                                      <div className="stat fitness CTL">
                                        <div className="label">Fitness</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">CTL</div>
                                        </div>
                                      </div>
                                      <div className="stat fatigue ATL">
                                        <div className="label">Fatigue</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">ATL</div>
                                        </div>
                                      </div>
                                      <div className="stat form TSB">
                                        <div className="label">Form</div>
                                        <div className="valueAndUnits">
                                          <div className="value">0</div>
                                          <div className="units">TSB</div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="barGraphs">
                                      <div className="barChartStatsCollection" />
                                    </div>
                                    <div className="textStats">
                                      <div className="statisticsSummary">
                                        <div className="statsRow total">
                                          <div>
                                            <label>Duration</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">00:00</span>
                                            <span className="units">hms</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Distance</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0.00</span>
                                            <span className="units">km</span>
                                          </div>
                                        </div>
                                        <div className="statsRow total">
                                          <div>
                                            <label>Training Stress Score</label>
                                          </div>
                                          <div className="valueContainer">
                                            <span className="value">0</span>
                                            <span className="units">TSS</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="atpStats">
                                      <div className="statisticsSummary" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="quickViewContainer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calender;
