import React from 'react';
import Header from '../component/Header';

const ManageSubscription = () => {
    return (
        <>
      <Header /> 
      <div id='zwsd'>
      <div id="mainContent zawczs">
		<h1 className="h1underline">Subscriptions & Payments</h1>

		<div className="notification-error">
			<div className="validation-summary-valid" data-valmsg-summary="true"><ul><li style={{ display: "none" }} />
</ul></div>
		</div>

<h2 className="darkGrey acctMgmtHead">Account Info</h2>

<div className="cf">
	<h4 className="acctSectionMainItem">Aaron Buchan</h4>

	<ul className="accountInfo cf">
		<li>
			<span className="label" style={{ display: "inline" }}>Username:</span>
			<span>aaroncrosstraining</span>
		</li>
	</ul>
</div>				{ /*
           				Note: the ViewDataDictionary parameter is required.  If BillingContact == null,
           				which is a valid case for new users who have not made any purchases through Zuora,
           				then without the ViewDataDictionary parameter, this method throws an exception:
           				http://stackoverflow.com/questions/650393/renderpartial-with-null-model-gets-passed-the-wrong-type
           				*/ }
<style>{`
	.contactInfoContain {
		width: 100%;
	}

	.contactInfoElement {
		color: #999999;
		width: calc(20% - 15px);
		margin-right: 10px;
		margin-bottom: 10px;
		display: inline-block;
		min-height: 28px;
		font-size: 1.2rem;
		vertical-align: top;
	}

	.contactInfoLabel {
		margin-bottom: 5px;
		font-family: "HelveticaNeueW01-75Bold";
	}

	.contactInfoValue {
		margin-bottom: 10px;
		font-family: "HelveticaNeueW01-55Roma";
	}
`}</style>

<h2 className="darkGrey acctMgmtHead">Billing Contact Info</h2>

		<div className="contactInfoContain">
			<div className="contactInfoElement">
				<div className="contactInfoLabel">First Name:</div>
				<div className="contactInfoValue">Aaron</div>
			</div>

			<div className="contactInfoElement">
				<div className="contactInfoLabel">Last Name:</div>
				<div className="contactInfoValue">Buchan</div>
			</div>

			<div className="contactInfoElement">
				<div className="contactInfoLabel">Email:</div>
				<div className="contactInfoValue">aaron@aaronbuchan.com</div>
			</div>

			<div className="contactInfoElement">
				<div className="contactInfoLabel">Country:</div>
				<div className="contactInfoValue">Australia</div>
			</div>

			<div className="contactInfoElement">
				<div className="contactInfoLabel">Postal Code:</div>
				<div className="contactInfoValue">5021</div>
			</div>
		</div>
	<div className="change">
		<a href="/account/coach/contactinformation" className="buttonGrey2">Edit</a>
	</div>


<h2 className="darkGrey acctMgmtHead">Active Subscription</h2>

	<div className="cf">
		<h4 className="acctSectionMainItem">Coach Edition Unlimited</h4>
		<div className="floatRight">

				<button id="editBillingTier" className="buttonBlue1">Edit</button>
					</div>

	</div>
		
		<script type="text/javascript">{`

			$(document).ready(function () {
				var $editSubscriptionModal = $("#edit-subscription-modal");
				var $editBillingTier = $("#editBillingTier");

				if ($editSubscriptionModal && $editBillingTier) {

					$editSubscriptionModal.dialog({
						modal: true,
						resizable: false,
						draggable: false,
						closeOnEscape: true,
						autoOpen: false,
						width: 745,
						dialogClass: 'jquery-modal-notitle',
						position: { my: "center top", at: "top+10%" },
						open: function () {
							var tpOverrideClass = "tp-notification-override";
							$('.ui-dialog a').blur();
							$('.ui-front').addClass(tpOverrideClass);
							$('.ui-widget-header').addClass(tpOverrideClass);
							$('.ui-widget-header a').addClass(tpOverrideClass);
							$('.ui-widget-overlay').addClass(tpOverrideClass);
						}
					});

					$editBillingTier.click(function () {
						$editSubscriptionModal.dialog("open");
					});

					$("#cancelSubscriptionChange", $editSubscriptionModal).click(function () {
						$editSubscriptionModal.dialog("close");
					});
				}
			});
		`}</script>
<h2 className="darkGrey acctMgmtHead">Billing</h2>

<div className="cf">
	<div className="marginBottom20">
		Group Name: <span>Aaron Buchan Multisport</span><br />
		Group Owner: <span>Aaron</span> <span>Buchan</span>
	</div>

		<div className="marginBottom20">
			<a href="/account/coachinggroup/billingHistory" className="buttonBlue1 marginTop10">Billing History</a><br />
		</div>

	<br />
	<a href="/tp/PlanReports" target="_blank" className="marginTop20 underline">Training Plan Reports</a>
</div><h2 className="darkGrey acctMgmtHead">Payment Methods</h2>

<div className="paymentMethodContain cf">

		<table className="payment-methods">
			<thead>
				<tr>
					<th className="alignLeft">Card Type</th>
					<th className="alignLeft">Card Number</th>
					<th className="alignLeft">Expiration</th>
					<th className="alignLeft">Cardholder Name</th>
					<th className="alignLeft">Card Options</th>
				</tr>
			</thead>
			<tbody id="payment-methods">
					<tr>
						<td>Visa</td>
						<td>************0922</td>
						<td>01/2026</td>
						<td>Random Name</td>
						<td>
								Default Card.<br /> Cannot be deleted.
						</td>
					</tr>
			</tbody>
		</table>

	<div className="addCardContain">
		<a href="/tp/CoachPayment/AddNewPaymentMethod" className="buttonGrey2 addCard">Add New</a>
	</div>
</div>
			<h2 className="darkGrey acctMgmtHead">Public Profile</h2>
			<a href="https://www.trainingpeaks.com/coach/my-profile" className="buttonGrey2">Edit Profile</a>
			<div style={{ marginTop: 70 }}>

				<div style={{ float: "right" }}>
					<button   className="buttonBlue1">Return to App</button>
				</div>

					<div style={{ float: "left" }}>
						<button id="close-account-button" className="buttonRed">Deactivate Account</button>
					</div>
			</div>

	</div> </div> 
    </>
    );
}

export default ManageSubscription;
