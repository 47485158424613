import React from "react";
import Header from "../../component/DashboardCom/Header";
import "../style.css";
import propng from "../../Images/propng.png";
import buchanIcon from "../../Images/buchanIcon.jpg";
import clock from "../../Images/clock.png";
import comment_6 from "../../Images/comment_6.png";
import speedMeter from "../../Images/speedMeter.png";
import markIcon from "../../Images/markIcon.jpg";
import alerticon from "../../Images/alerticon.png";
import alertRed from "../../Images/alertRed.png";
import alertRxxed from "../../Images/strength-icon-4.png";

import aTGB from "../../Images/img_453995.png";
import SideBar from "../SideBar/Index";



const DashMain = () => {
  let arr = [
    {
      id: 1,
    },
    {
      id: 5,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 5,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 5,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 5,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
  ];

  return (
    <>
      <Header />
      <div className="flexContent">
      
      <SideBar/>

        <div className="rightRow">
          <div className="headSection">
            <div className="tophead">
              <h2>Aaron Buchan's Coached Crew</h2>
              <img src={buchanIcon} alt="" />
            </div>
          </div>
          <div className="flxItemnext">
            <div className="colume1Full">
              <div className="colume1">
                <div className="C1">
                  <p>Athlete</p>
                </div>
                <div className="C2">
                  <span className="grayColor">S</span>
                  <span>S</span>
                  <span className="grayColor">T</span>
                  <span>W</span>
                  <span>T</span>
                  <span className="grayColor">F</span>
                  <span>S</span>
                  <span>S</span>
                  <span className="grayColor">M</span>
                  <span>T</span>
                  <span>W</span>
                  <span>T</span>
                  <span className="grayColor">F</span>
                  <span>S</span>
                </div>
                <div className="C3">
                  <p>Alerts</p>
                  <img src={alerticon} alt="" />
                </div>
              </div>
              <div className="colume2">
                <span>All</span>
                <span>
                  <img src={clock} alt="" />
                </span>
                <span>
                  <img src={comment_6} alt="" />
                </span>
                <span>
                  <img src={speedMeter} alt="" />
                </span>

                <span className="thisGreen">230</span>
                <span className="borderlefth">
                  <img src={markIcon} alt="" />
                </span>
              </div>
            </div>

            <div className="innerFullwitg">
              <div className="FllWid11">
                {arr.map((e) => {
                  return (
                    <div className="inneFllWid1 FllWid1">
                      <div className="t1K">
                        <div className="settingProfile inner">
                          <button>
                            <img src={propng} alt="" />
                            Arron Buchan
                          </button>
                        </div>
                      </div>
                      <div className="t2K">
                        <span></span>
                        <span></span>
                        <span></span> <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      <div className="t3K">
                        {e.id === 5 ? <img src={alertRed} alt="" /> : null}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="FllWid22">
                <div className="heightselll">
                  <div className="Yesterday">Yesterday</div>
                  {arr.map((e) => {
                    return (
                      <div className="cell">
                        <p>
                          <strong>petra brosch</strong> updated metrics for
                          Friday, 22 September, 2023
                        </p>
                        <div className="settingProfile right">
                          <button>
                            <img src={propng} alt="" />
                            <strong> Arron Buchan </strong> &nbsp;&nbsp;Stress
                            Level: Avg. 32
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashMain;
