import React from "react";
import propng from "../Images/propng.png";
import buchanIcon from "../Images/buchanIcon.jpg";
import clock from "../Images/clock.png";
import comment_6 from "../Images/comment_6.png";
import speedMeter from "../Images/speedMeter.png";
import markIcon from "../Images/markIcon.jpg";
import alerticon from "../Images/alerticon.png";
import alertRed from "../Images/alertRed.png";
import Header from "../component/DashboardCom/Header";

const ATP = () => {
  

  return (
    <>
      <Header />
      <div className="flexContent">
        <div className="SideRow">
          <button>
            <div className="iconthis">
              <img src={propng} alt="" />
              <div className="libraryTitle">Athlete Library</div>
            </div>
          </button>
        </div>

        <div className="rightRow">
        <div className="appContainerMainContentContainer"><div className="atp appContainerMainContentContainerScrollContainer" style={{}}><div className="row frameworkHeader planHeader"><div className="planHeaderView frameworkHeaderView"><div className="region headerRegion"><div className="planTitleLabel calendarMonthLabel frameworkTitle"><label className="headerTitle" data-tooltip="Select ATP">Annual Training Plan</label></div></div><div className="region controlsRegion"><div className="navigation"><div className="printRefreshButtons"><button className="editButton hidden" data-tooltip="Edit ATP" tabIndex={-1} />
        {/* <button className="addButton" data-tooltip="Create new ATP" /> */}
        </div></div></div><div className="region titleRegion"><div className="athleteInformation"><h2 className="athleteInformation athleteName clickable" data-tooltip="Edit athlete's settings">ava  andrews</h2></div></div></div></div><div className="frameworkMainWrapper"><div className="frameworkScrollableContainer frameworkMainContentWell planContainer"><div className="planContainer"><div className="scrollHeader createAtpPrompt"><div className="createPromptHeader" /></div><div className="scrollable planRegion createAtpPrompt"><div className="createAtp"><div className="createAtpDialog"><div className="header">Create an Annual Training Plan</div><button className="createAtpButton">Get Started</button></div></div></div></div></div><div className="quickViewContainer" /></div><div className="calendarSettingsIcons"><div className="tomahawkButton atpSettingsButton" data_cy="tomahawkButton"><div className="largeHamburgerButton" data-tooltip="Settings" data-tooltip-alignment="above" /></div></div></div></div>
        </div>
      </div>
    </>
  );
};

export default ATP;
