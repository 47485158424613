import React, { useState } from "react";
import alertRxxed from "../../Images/strength-icon-4.png";
import aTGB from "../../Images/img_453995.png";
import propng from "../../Images/propng.png";

const SideBar = () => {

    let arr1 = [
        {
            name: 'My Athletes',
            num: 25
        },
        {
            name: '10 kgs in 10 days',
            num: 0
        },
        {
            name: '12 wee difference program...',
            num: 2
        },
        {
            name: '2 week free trial (0)',
            num: 0
        }, 
    ]

    let arr2 = [
        {
            name: 'Recents',
            num: 25
        },
        {
            name: 'My Library',
            num: 0
        },
        {
            name: '10 week 70.3 week 1',
            num: 2
        },
        {
            name: '10 week 70.3 week 2 ',
            num: 0
        }, 
    ]

    let arr3 = [
        {
            name: 'My Plans',
            num: 25
        },
        {
            name: 'Shared Plans',
            num: 0
        },
        {
            name: '12 week pathway prediction program',
            num: 2
        },
        {
            name: '12 week pathwayprediction plan',
            num: 0
        }, 
    ]

  const [Width, setWidth] = useState(0);
  const [state, setState] = useState(false);
  const [Arr, setArr] = useState([]);

  const [OPT, setOPT] = useState(0);

  function firsTab(e) {

    setOPT(e)
    if (e === 1) {
        setArr(arr1) 
        setState(true)
    }

    if (e === 2) {
        setArr(arr2)
        setState(true)
    }

    if (e === 3) {
        setArr(arr3)
        setState(true)
    }

  }

  const containerStyle = {
    width: state ? "300px" : "68px",
  };

  return (
    <div className="SideRow" style={containerStyle}>
      <div className="buttnCOl">
        <button onClick={() => firsTab(1)}>
          <div className="iconthis">
            <img src={propng} alt="" />
            <div className="libraryTitle">Athlete Library</div>
          </div>
        </button>
        <button onClick={() => firsTab(2)}>
          <div className="iconthis">
            <img src={alertRxxed} alt="" />
            <div className="libraryTitle">Workout Library</div>
          </div>
        </button>
        <button onClick={() => firsTab(3)}>
          <div className="iconthis">
            <img src={aTGB} alt="" />
            <div className="libraryTitle">Training Plans</div>
          </div>
        </button>
      </div>

      {state ? (
        <div className="SlideCOl">
          <div
            className="activeLibraryContainer overflowHidden"
            data_cy="activeLibraryContainer"
          >
            <div
              className="coachAthleteLibraryContainer"
              data_cy="coachAthleteLibraryContainer"
            >
              <div
                className="coachAthleteLibrarySubContainer"
                data_cy="coachAthleteLibrarySubContainer"
              >
                <div
                  className="coachAthleteLibrary"
                  data_cy="coachAthleteLibrary"
                >
                  <div className="coachAthleteLibraryHeader">
                    <span className="coachAthleteLibraryHeaderTitle">
                      Athlete Library
                    </span>
                    <div
                      className="  buttonComponent"
                      data-tooltip="Close Athlete Library"
                      data_cy=""
                      onClick={()=> setState(!state)}
                    >
                      <div className="close  buttonComponentIcon" />
                    </div>
                  </div>
                  <div className="coachAthleteLibraryControls">
                    <div className="searchAndAddContainer">
                      <div className="addOptionsButton" tabIndex={0}>
                        <div className="addOptionsButtonIcon" />
                      </div>
                      <div className="coachAthleteLibrarySearch">
                        <input
                          type="text"
                          className="coachAthleteLibrarySearchInput"
                          placeholder="Search"
                          value={true}
                        />
                        <div className="coachAthleteLibrarySearchIcon" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="coachAthleteLibraryAthletesContainer"
                    data_cy="athletesContainer"
                  >
                     
                    {/* .................... */}
                    { Arr.map((e)=>{
                        return (
                            <div
                            className="athleteGroup"
                            data_cy="athleteGroup"
                            draggable
                          >
                            <div
                              className="coachAthleteLibraryFolder"
                              data_cy="coachAthleteLibraryFolder"
                            >
                              <div className="coachAthleteLibraryFolderNameContainer">
                                <div className="coachAthleteLibraryFolderArrowIcon" />
                                <span className="coachAthleteLibraryFolderTitle">
                                   {e.name}
                                </span>
                                <span className="coachAthleteLibraryFolderCount">
                                {e.num}
                                </span>
                              </div>
                              <div
                                className="foldersettingsButtonContainer"
                                tabIndex={0}
                              >
                                <div
                                  className="foldersettingsButton"
                                  data_cy="folderSettingsButton"
                                >
                                  <div className="foldersettingsButtonIcon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    }) 
                    } 
                    {/* .................... */}

                  
                     
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SideBar;
