import React, { useState } from "react";
import logo from "../../Images/logo.png";
import profileIcon from "../../Images/profileIcon.jpg";
import bell from "../../Images/bell.svg";
import fullSize from "../../Images/fullSize.svg";
import { Link } from "react-router-dom";
import PopupModel from "../../Pages/PopupModel";

const Header = () => {
  const [count, setCount] = useState(0);

  let MenuArr = [
    {
      id: 1,
      Name: "Home",
      Active: "active",
    },
    {
      id: 2,
      Name: "Calender",
    },
    {
      id: 3,
      Name: "Dashboard",
    },
    {
      id: 4,
      Name: "ATP",
    },
    {
      id: 5,
      Name: "ManageAthletes",
    },
  ];

  return (
    <>
      <div className="mainHedaer">
        <div className="mainHedaerInner">
          <div className="logohere">
            <img src={logo} alt="" />
          </div>
          <div className="menuhere">
            <div className="InnerMenu">
              {MenuArr.map((e) => {
                return (
                  <>
                    <Link to={e.Name}>
                      <button
                        // className={e.Name === count ? "active" : null}
                        onClick={() => setCount(e.Name)}
                      >
                        {e.Name}
                      </button>
                    </Link>
                  </>
                );
              })}
            </div>
          </div>
          <div className="settingProfile">
            <button>
              <img src={profileIcon} alt="" /> Arron Buchan 
            </button>

            <button className="SVGimg">
              <img src={bell} alt="" />
            </button>
            <button className="SVGimg">
              <img src={fullSize} alt="" />
            </button>
          </div>
        </div>
      </div>
      {/* <PopupModel/> */}
    </>
  );
};

export default Header;
